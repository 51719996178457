import { createStore } from "vuex";
import axios from 'axios';

export default createStore({
  state: {
    poem_data: {},
  },
  mutations: {
      SET_POEM(state, poem) {
        state.poem_data = poem
    }
  },
  actions: {    
      getPosts({ commit }) {
        axios.get('https://nje-utils.herokuapp.com/get_poem/')
            .then(response => {
                commit('SET_POEM', response.data)
        })
    }},
  modules: {},
  getters: {
    getLines: (state) => (idx) => {
      return state.poem_data[idx];
    },
  },
});
