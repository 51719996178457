<template>
  <div id="header">
    <div id="page-title"><h1>HELIX</h1></div>
    <div id="nav">
      <router-link to="/">About</router-link> |
      <router-link to="/poem/41">Poem</router-link>
    </div>
    <router-view />
  </div>
  <div id="footer">
    <p>COPYRIGHT &#169; {{ new Date().getFullYear() }} &nbsp; &nbsp;NATALIE JANE EDSON</p>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;1,400;1,500&display=swap");

:root {
  --bg-color: #fbf9ef;
  --title-color: #2c2811;
  --text-color: #02020b;
  --link-color: #7e727a;
}

#app {
  font-family: "Playfair Display", Helvetica, Arial, sans-serif;
  text-align: center;
  color: var(--text-color);
  background-color: var(--background-color);
}

#header {
  display: inline;
  width: 100%;
}

#page-title {
  font-style: italic;
  font-weight: 400;
  display: inline-block;
  float: left;
  margin-left: 30px;
  color: var(--title-color);
}

#nav {
  padding: 30px;
  float: right;
  display: inline-block;
  font-weight: 400;
}

#nav a {
  color: var(--text-color);
  text-decoration: underline;
}

#footer {
  font-size: 0.75em;
  padding: 175px 0 50px 0;
}

/* POEM */

.poem {
  display: inline-block;
  width: 100%;
}

.about {
  display: inline-block;
  margin: 4em 6em;
}
</style>
